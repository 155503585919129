const styles = {
  root: 'relative h-screen bg-center bg-cover bg-black',
  pattern: 'absolute h-full w-full bg-polka',

  container: 'h-full w-full -mt-20 sm:-mt-32 p-6 md:p-28 flex items-end',
  content:
    'text-5xl font-semibold flex items-end justify-between w-full text-white',
  title: {
    base: 'font-nbi-pro text-xl sm:text-5xl xl:text-7xl tracking-display leading-6 duration-200 cursor-pointer z-20 flex flex-row items-start',
    inactive: 'opacity-40',
    active: 'opacity-100',
  },
};

export default styles;
